import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./component/header";
import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  addDoc,
  Timestamp,
  onSnapshot,
} from "firebase/firestore";
import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { MdHowToVote } from "react-icons/md";
import { useCookies } from "react-cookie";

import { db } from "./service/firebase";
import { Cource, Vote } from "./types/firebase";
import { Loading } from "./component/loading";
import { useLocation } from "react-router-dom";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const [cource, setCource] = useState<Cource>();
  const [votes, setVotes] = useState<(Vote & { id: string })[]>([]);
  const [chat, setChat] = useState("");

  const [cookies, setCookie] = useCookies(["votes"]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (!id) {
      setError("コースIDが入力されていません");
      return;
    }

    const getFirebase = async () => {
      // コースの取得
      const courcesRef = collection(
        db,
        "cources"
      ) as CollectionReference<Cource>;
      const courceDoc = doc(courcesRef, id);
      const courceDocData = await getDoc(courceDoc);
      const courceData = courceDocData.data();
      if (!courceData) {
        setError("コースIDが見つかりません");
        setIsLoading(false);
        return;
      }
      if (!courceData?.enable) {
        setError("コースが有効ではありません");
        setIsLoading(false);
        return;
      }
      setCource(courceData);

      // 投票の取得
      const newVote: (Vote & { id: string })[] = [];
      // 既存投票チェック
      const alreadyVoted = cookies.votes
        ? (cookies.votes.split(",") as string[])
        : [];
      const votesRef = collection(
        db,
        "cources",
        id,
        "votes"
      ) as CollectionReference<Vote>;
      const voteDocs = await getDocs(votesRef);
      voteDocs.docs.forEach((voteDoc) => {
        const voteData = voteDoc.data();
        if (alreadyVoted.indexOf(voteDoc.id) === -1 && voteData.enable) {
          newVote.push({ ...voteData, id: voteDoc.id });
        }
      });
      setVotes(newVote);

      setIsLoading(false);
      return;
    };
    getFirebase();
  }, [cookies, id]);

  // 投票のリアルタイム取得
  useEffect(() => {
    if (!id) return;
    const votesRef = collection(
      db,
      "cources",
      id,
      "votes"
    ) as CollectionReference<Vote>;

    const unsub = onSnapshot(votesRef, (docs) => {
      // 投票の取得
      const newVote: (Vote & { id: string })[] = [];
      // 既存投票チェック
      const alreadyVoted = cookies.votes
        ? (cookies.votes.split(",") as string[])
        : [];

      docs.forEach((voteDoc) => {
        const voteData = voteDoc.data();
        if (alreadyVoted.indexOf(voteDoc.id) === -1 && voteData.enable) {
          newVote.push({ ...voteData, id: voteDoc.id });
        }
      });
      setVotes(newVote);
    });

    return () => unsub();
  }, [id, cookies]);

  const handleChatSend = async () => {
    const chatRef = collection(db, "cources", id!, "posts");
    try {
      await addDoc(chatRef, { text: chat, date: Timestamp.now() });
      toast.success("送信しました", { position: "bottom-center" });
      setChat("");
    } catch (e) {
      console.error(e);
      toast.error("送信に失敗しました", { position: "bottom-center" });
    }
  };

  const handleVote = async (voteId: string, text: string) => {
    const voteRef = collection(db, "cources", id!, "votes", voteId, "logs");
    try {
      await addDoc(voteRef, { vote: text });
      toast.success("送信しました", { position: "bottom-center" });
      // cookieで送信済み管理
      if (cookies.votes) {
        setCookie("votes", cookies.votes + "," + voteId, {
          maxAge: 60 * 60 * 24 * 265,
        });
      } else {
        setCookie("votes", voteId, { maxAge: 60 * 60 * 24 * 265 });
      }
    } catch (e) {
      console.error(e);
      toast.error("送信に失敗しました", { position: "bottom-center" });
    }
  };

  return (
    <Box className="App">
      <Header />
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : isLoading ? (
        <Loading />
      ) : (
        <Box className="App-header">
          <Typography variant="h4">{cource?.title}</Typography>
          <Typography variant="h6">
            開始 {dayjs(cource?.date.toDate()).format("YYYY/MM/DD HH:mm:ss")}
          </Typography>
          <Divider />
          <Typography
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            marginTop="20px"
            variant="h5"
          >
            <BsFillChatLeftTextFill />
            チャット
          </Typography>
          <TextField
            value={chat}
            onChange={(ev) => setChat(ev.target.value)}
            size="small"
            multiline
          ></TextField>
          <Button
            onClick={handleChatSend}
            sx={{ marginY: "10px" }}
            fullWidth
            variant="contained"
          >
            送信
          </Button>
          <Divider />
          <Typography
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            marginTop="20px"
            variant="h5"
          >
            <MdHowToVote />
            投票
          </Typography>
          {votes.length === 0 ? (
            <>未投票の投票はありません</>
          ) : (
            votes.map((vote, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{ border: 1, borderRadius: 1, borderColor: "#223462" }}
                  padding="5px"
                >
                  <Typography variant="h6">{vote.title}</Typography>
                  <Divider />
                  {vote.selects.map((select, idx) => {
                    return (
                      <Button
                        sx={{ marginY: "3px" }}
                        key={idx}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => handleVote(vote.id, select)}
                      >
                        {select}
                      </Button>
                    );
                  })}
                </Box>
              );
            })
          )}
        </Box>
      )}
    </Box>
  );
}

export default App;
