import { Button, AppBar, Box, Toolbar, Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../service/firebase";

export default function Header() {
  return (
    <Box height="56px" sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            bidIreCT
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export function AdminHeader() {
  return (
    <Box height="56px" sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            bidIreCT - Admin
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export function AdminHeaderWithSignout() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut(auth);
    navigate("/admin/login");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            bidIreCT - Admin
          </Typography>
          <Button onClick={handleSignOut} color="inherit">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
