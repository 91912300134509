import { FC, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Modal,
} from "@mui/material";
import {
  collection,
  doc,
  updateDoc,
  CollectionReference,
} from "firebase/firestore";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { MdQrCode2 } from "react-icons/md";

import { Cource, Vote } from "../types/firebase";
import { db } from "../service/firebase";
import { QRCodeCanvas } from "qrcode.react";

export const CourceCard: FC<{
  id: string;
  title: string;
  date: Timestamp;
  chatCount: number;
  votes: (Vote & { id: string })[];
  isEnabled: boolean;
}> = ({ id, title, date, chatCount, votes, isEnabled }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const handleEnableChange = async (state: boolean) => {
    const courceRef = collection(db, "cources") as CollectionReference<Cource>;
    const courceDoc = doc(courceRef, id!);
    await updateDoc(courceDoc, { enable: state });
  };

  const handleChatRouting = () => {
    const params = new URLSearchParams();
    params.append("id", id);
    navigate("/admin/chat?" + params.toString());
  };

  const handleVoteRouting = (voteId: string) => {
    const params = new URLSearchParams();
    params.append("id", id);
    params.append("vote", voteId);
    navigate("/admin/vote?" + params.toString());
  };

  return (
    <Card
      variant="outlined"
      sx={{ backgroundColor: "whitesmoke", marginY: "5px" }}
    >
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => setIsOpen(true)}
              startIcon={<MdQrCode2 />}
            >
              QR
            </Button>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          </Stack>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                defaultChecked={isEnabled}
                onChange={(ev) => handleEnableChange(ev.target.checked)}
              />
            }
            label="Enable"
          />
        </Stack>
        <Typography color="textSecondary" gutterBottom>
          {dayjs(date.toDate()).format("YYYY/MM/DD HH:mm:ss")}
        </Typography>
        <Stack direction="column" alignItems="start" spacing={1}>
          <Button
            onClick={handleChatRouting}
            size="small"
            variant="contained"
            color="info"
          >
            Open Chat ({chatCount} content)
          </Button>

          {votes.map((vote, idx) => {
            return (
              <Button
                key={idx}
                onClick={() => handleVoteRouting(vote.id)}
                size="small"
                variant="contained"
                color="success"
              >
                Open Vote #{idx + 1} 「{vote.title}」
              </Button>
            );
          })}
        </Stack>
      </CardContent>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <QRCodeCanvas value={`${window.location.origin}?id=${id}`} />
        </Box>
      </Modal>
    </Card>
  );
};

export const ChatCard: FC<{
  text: string;
  date: Timestamp;
  onClick: () => void;
}> = ({ text, date, onClick }) => {
  return (
    <Box
      bgcolor="whitesmoke"
      textAlign="left"
      marginY="2px"
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "lightgray",
      }}
      onClick={onClick}
    >
      <Typography fontSize={20}>{text}</Typography>
      <Typography fontSize={10} color="lightgray">
        {dayjs(date.toDate()).format("YYYY/MM/DD HH:mm:ss")}
      </Typography>
    </Box>
  );
};

export const PinnedChatCard: FC<{
  text: string;
  date: Timestamp;
  onClick: () => void;
}> = ({ text, date, onClick }) => {
  return (
    <Box
      bgcolor="#FFFF99"
      textAlign="left"
      marginY="2px"
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "lightgray",
      }}
      onClick={onClick}
    >
      <Typography fontSize={20}>{text}</Typography>
      <Typography fontSize={10} color="gray">
        {dayjs(date.toDate()).format("YYYY/MM/DD HH:mm:ss")}
      </Typography>
    </Box>
  );
};
