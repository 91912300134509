import ReactLoading from "react-loading";
import { Box } from "@mui/material";

export function Loading() {
  return (
    <Box
      className="App-header"
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <ReactLoading
        type="spinningBubbles"
        color="#29A3D9"
        height={80}
        width={80}
      />
    </Box>
  );
}

export function AdminLoading() {
  return (
    <Box
      className="Admin-app-header"
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <ReactLoading
        type="spinningBubbles"
        color="#9F536C"
        height={200}
        width={200}
      />
    </Box>
  );
}
