import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import Login from "./admin/login";
import Vote from "./admin/vote";
import Chat from "./admin/chat";
import AdminHome from "./admin/home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/admin",
    element: <AdminHome />,
  },
  {
    path: "/admin/login",
    element: <Login />,
  },
  {
    path: "/admin/vote",
    element: <Vote />,
  },
  {
    path: "/admin/chat",
    element: <Chat />,
  },
]);

const theme = createTheme({
  palette: {
    primary: { main: "#29A3D9" },
    secondary: { main: "#9F536C" },
    info: { main: "#DFD697" },
    success: { main: "#93B58D" },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CookiesProvider>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <Toaster />
    </ThemeProvider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
