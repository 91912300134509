import { useEffect, useState } from "react";
import "../App.css";
import { AdminHeaderWithSignout } from "../component/header";
import {
  collection,
  CollectionReference,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

import { db, auth } from "../service/firebase";
import { Cource, Post } from "../types/firebase";
import { ChatCard, PinnedChatCard } from "../component/card";
import { AdminLoading } from "../component/loading";

type ChatProps = Post & {
  id: string;
  pinned: boolean;
};

export default function AdminChat() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // check auth
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthed(true);
      } else {
        navigate("/admin/login", { replace: true });
      }
    });
  }, [navigate]);

  const queryParam = new URLSearchParams(location.search);
  const id = queryParam.get("id");

  const [title, setTitle] = useState("");
  const [pinmode, setPinmode] = useState(true);
  const [chats, setChats] = useState<ChatProps[]>([]);
  const [pinnedChats, setPinnedChats] = useState<ChatProps[]>([]);
  const [loadedChats, setLoadedChats] = useState<string[]>();

  useEffect(() => {
    if (!isAuthed || !id) return;
    const getFirebase = async () => {
      // コースの取得
      const courcesRef = collection(
        db,
        "cources"
      ) as CollectionReference<Cource>;
      const courceDoc = doc(courcesRef, id);
      const courceDocData = await getDoc(courceDoc);
      const courceData = courceDocData.data();
      setTitle(courceData ? courceData.title : "");

      // チャットの取得
      const newChats: ChatProps[] = [];
      const loadedIds: string[] = [];

      const postsRef = collection(
        db,
        "cources",
        id,
        "posts"
      ) as CollectionReference<Post>;
      const postDocs = await getDocs(postsRef);

      postDocs.forEach((postDoc) => {
        const postData = postDoc.data();
        newChats.push({ ...postData, id: postDoc.id, pinned: false });
        loadedIds.push(postDoc.id);
      });

      setChats(newChats);
      setLoadedChats(loadedIds);
      setIsLoading(false);
    };
    getFirebase();
  }, [isAuthed, id]);

  const handlePinned = (idx: number) => {
    setPinnedChats((current) => [chats[idx], ...current]);
  };

  const handleUnPinned = (idx: number) => {
    const newList = pinnedChats.concat();
    newList.splice(idx, 1);
    setPinnedChats(newList);
  };

  // チャットのリアルタイム取得
  useEffect(() => {
    if (!id) return;
    const postsRef = collection(
      db,
      "cources",
      id,
      "posts"
    ) as CollectionReference<Post>;
    const unsub = onSnapshot(postsRef, (docs) => {
      docs.forEach((postDoc) => {
        if (loadedChats?.indexOf(postDoc.id) === -1) {
          const postData = postDoc.data();
          setChats((current) => [
            { ...postData, id: postDoc.id, pinned: false },
            ...current,
          ]);
          setLoadedChats((current) =>
            current ? [...current, postDoc.id] : [postDoc.id]
          );
        }
      });
    });

    return () => unsub();
  }, [id, loadedChats]);

  return (
    <Box className="App">
      <AdminHeaderWithSignout />
      {isLoading ? (
        <AdminLoading />
      ) : (
        <Box className="Admin-app-header">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">{title}</Typography>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={pinmode}
                  onChange={() => setPinmode(!pinmode)}
                />
              }
              label="Pin mode"
            />
          </Stack>
          <Divider />
          <Grid container spacing={2}>
            <Grid
              textAlign="center"
              item
              xs={12}
              sm={pinmode ? 6 : 0}
              hidden={!pinmode}
            >
              <Typography variant="h6">Pinned</Typography>
              <Box
                height="calc(100vh - 160px)"
                overflow="scroll"
                paddingX="2px"
              >
                {pinnedChats.map((chat, idx) => {
                  return (
                    <Box key={idx}>
                      <PinnedChatCard
                        key={idx}
                        text={chat.text}
                        date={chat.date}
                        onClick={() => handleUnPinned(idx)}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid xs={12} textAlign="center" item sm={pinmode ? 6 : 12}>
              <Typography variant="h6">Timeline</Typography>
              <Box
                height="calc(100vh - 160px)"
                overflow="scroll"
                paddingX="2px"
              >
                {chats.map((chat, idx) => {
                  return (
                    <Box key={idx}>
                      <ChatCard
                        key={idx}
                        text={chat.text}
                        date={chat.date}
                        onClick={() => handlePinned(idx)}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
