import { useEffect, useState } from "react";
import "../App.css";
import { AdminHeaderWithSignout } from "../component/header";
import { collection, CollectionReference, getDocs } from "firebase/firestore";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { db, auth } from "../service/firebase";
import { Cource, Post, Vote } from "../types/firebase";
import { CourceCard } from "../component/card";
import { AdminLoading } from "../component/loading";

type CourceProps = Cource & {
  id: string;
  chatCount: number;
  votes: (Vote & { id: string })[];
};

export default function AdminHome() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthed(true);
      } else {
        navigate("/admin/login", { replace: true });
      }
    });
  }, [navigate]);

  const [cources, setCources] = useState<CourceProps[]>([]);

  useEffect(() => {
    if (!isAuthed) return;
    const getFirebase = async () => {
      // コースの取得
      const newCources: CourceProps[] = [];
      const courcesRef = collection(
        db,
        "cources"
      ) as CollectionReference<Cource>;
      const courceDocs = await getDocs(courcesRef);

      for (const courceDoc of courceDocs.docs) {
        const courceData = courceDoc.data();
        // チャット数の取得
        const postsRef = collection(
          db,
          "cources",
          courceDoc.id,
          "posts"
        ) as CollectionReference<Post>;
        const postDocs = await getDocs(postsRef);

        // 投票数の取得
        const votesRef = collection(
          db,
          "cources",
          courceDoc.id,
          "votes"
        ) as CollectionReference<Vote>;
        const voteDocs = await getDocs(votesRef);
        const voteDatas = voteDocs.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });

        newCources.push({
          ...courceData,
          id: courceDoc.id,
          chatCount: postDocs.size,
          votes: voteDatas,
        });
      }
      setCources(newCources);
      setIsLoading(false);
    };
    getFirebase();
  }, [isAuthed]);

  return (
    <Box className="App">
      <AdminHeaderWithSignout />
      {isLoading ? (
        <AdminLoading />
      ) : (
        <Box className="Admin-app-header">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end"
          >
            <Typography variant="h4">Welcome</Typography>
            <Typography variant="h5">{cources.length} course(s)</Typography>
          </Stack>
          <Divider />
          {cources.map((cource, idx) => {
            return (
              <CourceCard
                key={idx}
                id={cource.id}
                title={cource.title}
                date={cource.date}
                chatCount={cource.chatCount}
                votes={cource.votes}
                isEnabled={cource.enable}
              />
            );
          })}
          <Divider />
          <Button color="secondary" variant="contained">
            Add
          </Button>
        </Box>
      )}
    </Box>
  );
}
