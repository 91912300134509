import { useEffect, useState } from "react";
import "../App.css";
import { AdminHeaderWithSignout } from "../component/header";
import {
  collection,
  CollectionReference,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";

import { db, auth } from "../service/firebase";
import { Cource, Vote as VoteProp, VoteLog } from "../types/firebase";
import { AdminLoading } from "../component/loading";

const COLORS = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];
export default function Vote() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // check auth
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthed(true);
      } else {
        navigate("/admin/login", { replace: true });
      }
    });
  }, [navigate]);

  const queryParam = new URLSearchParams(location.search);
  const id = queryParam.get("id");
  const voteId = queryParam.get("vote");

  const [title, setTitle] = useState("");
  const [vote, setVote] = useState<VoteProp>();
  const [data, setData] = useState<{ label: string; count: number }[]>([]);
  const [loadedVotes, setLoadedVotes] = useState<string[]>();

  useEffect(() => {
    if (!isAuthed || !id || !voteId) return;
    const getFirebase = async () => {
      const newData: { label: string; count: number }[] = [];

      // コースの取得
      const courcesRef = collection(
        db,
        "cources"
      ) as CollectionReference<Cource>;
      const courceDoc = doc(courcesRef, id);
      const courceDocData = await getDoc(courceDoc);
      const courceData = courceDocData.data();
      setTitle(courceData ? courceData.title : "");

      // 投票の取得
      const voteRef = collection(
        db,
        "cources",
        id,
        "votes"
      ) as CollectionReference<VoteProp>;
      const voteDoc = doc(voteRef, voteId);
      const voteDocData = await getDoc(voteDoc);
      const voteData = voteDocData.data();
      setVote(voteData);
      voteData?.selects.forEach((select) => {
        newData.push({ label: select, count: 0 });
      });

      // 投票ログの取得
      const newLoadedVote: string[] = [];

      const votesRef = collection(
        db,
        "cources",
        id,
        "votes",
        voteId,
        "logs"
      ) as CollectionReference<VoteLog>;
      const voteDocs = await getDocs(votesRef);

      voteDocs.forEach((voteDoc) => {
        const voteData = voteDoc.data();
        newLoadedVote.push(voteDoc.id);
        const curData = newData.find((data) => data.label === voteData.vote);
        if (curData) curData.count += 1;
      });

      setData(newData);
      setLoadedVotes(newLoadedVote);
      setIsLoading(false);
    };
    getFirebase();
  }, [isAuthed, id, voteId]);

  // 投票のリアルタイム取得
  useEffect(() => {
    if (!id || !voteId) return;
    const votesRef = collection(
      db,
      "cources",
      id,
      "votes",
      voteId,
      "logs"
    ) as CollectionReference<VoteLog>;

    const unsub = onSnapshot(votesRef, (docs) => {
      docs.forEach((voteDoc) => {
        const voteData = voteDoc.data();
        if (loadedVotes?.indexOf(voteDoc.id) === -1) {
          setData((current) =>
            current.map((dat) =>
              dat.label === voteData.vote
                ? { ...dat, count: dat.count + 1 }
                : dat
            )
          );
          setLoadedVotes((current) =>
            current ? [...current, voteDoc.id] : [voteDoc.id]
          );
        }
      });
    });

    return () => unsub();
  }, [id, voteId, loadedVotes]);

  const handleChangeEnable = async (state: Boolean) => {
    const voteRef = collection(
      db,
      "cources",
      id!,
      "votes"
    ) as CollectionReference<VoteProp>;
    const voteDoc = doc(voteRef, voteId!);
    await updateDoc(voteDoc, { enable: state });
  };

  return (
    <Box className="App">
      <AdminHeaderWithSignout />
      {isLoading ? (
        <AdminLoading />
      ) : (
        <Box className="Admin-app-header">
          <Typography variant="h6">{title}</Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end"
          >
            <Typography variant="h4">{vote?.title}</Typography>
            <Typography variant="h6">
              {data.reduce((sum, dat) => sum + dat.count, 0)} vote(s)
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  defaultChecked={vote?.enable}
                  onChange={(ev) => handleChangeEnable(ev.target.checked)}
                />
              }
              label="Enable"
            />
          </Stack>
          <Divider />
          <Grid container padding={4} height="80vh">
            <Grid item sm={6}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={600}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="label" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#8884d8">
                    {data.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item sm={6}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={500} height={600}>
                  <Pie
                    data={data}
                    dataKey="count"
                    cx="50%"
                    cy="50%"
                    outerRadius={240}
                    fill="#8884d8"
                    labelLine={false}
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      index,
                    }) => {
                      const radius =
                        innerRadius + (outerRadius - innerRadius) * 0.5;
                      const x =
                        cx + radius * Math.cos((-midAngle * Math.PI) / 180);
                      const y =
                        cy + radius * Math.sin((-midAngle * Math.PI) / 180);

                      return (
                        <text
                          x={x}
                          y={y}
                          fill="#333"
                          textAnchor={x > cx ? "start" : "end"}
                          dominantBaseline="central"
                        >
                          {data[index].label}
                        </text>
                      );
                    }}
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
